<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import HeaderAsesmenHC from "./header_asesmen-hc.vue";
import axios from "axios";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import router from "@/router";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    HeaderAsesmenHC,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      id_kompetensi: null,
      id_jadwal: null,
      id_jadwal_peserta: null,
      id_jadwal_batch: null,
      level: null,
      id_status: null,
      kritik_dan_saran: null,
      modalTerimakasih: false,
    };
  },
  methods: {
    async cekParamSoal() {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      this.paramSoal = this.$route.query.soal;
      // console.log(this.paramSoal);
      if (this.paramSoal === null || this.paramSoal === "" || !this.$route.query.soal) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Soal tidak ditemukan, silahkan menuju ke halaman dashboard",
          showCancelButton: false,
          confirmButtonText: "Kembali ke Dashboard",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({
              name: "dashboard-assesmen-hc",
            });
          }
        });
      } else {
        let get_param = CryptoJS.AES.decrypt(this.paramSoal, "4lfahumaNeEd5ME!").toString(CryptoJS.enc.Utf8);
        await this.getDataFromParam(get_param);
        Swal.close();
      }
    },
    getDataFromParam(param) {
      let splittedArray = param.split("_");
      this.id_jadwal = splittedArray[0];
      this.id_jadwal_peserta = splittedArray[1];
      this.id_jadwal_batch = splittedArray[2];
      this.id_kompetensi = splittedArray[3];
      this.level = splittedArray[4];
      this.id_status = splittedArray[5];
    },

    putStatusPeserta() {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "peserta/jadwal-peserta/update-jawaban-peserta/" + this.id_status,
        data: {
          id_jadwal: this.id_jadwal,
          idjadwal_peserta: this.id_jadwal_peserta,
          idjadwal_batch: this.id_jadwal_batch,
          idkompetensi: this.id_kompetensi,
          level: this.level,
          status: 3,
          kritik_saran: this.kritik_dan_saran,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_peserta,
        },
      };
      axios(config_store_data)
        .then((response) => {
          Swal.close();
          console.log(response); // Check the response
          Swal.fire({
            icon: "success",
            title: "Selesai!",
            text: "Jawaban berhasil disimpan",
          }).then((result) => {
            if (result.isConfirmed) {
              // router.push({
              //     name: 'dashboard-assesmen-hc'
              // });
              //   this.modalTerimakasih = true;
              Swal.fire({
                title: "Terimakasih",
                html:
                  '<div style="display:flex; align-items:center;">' +
                  '<div style="margin-left: 10px; width: 100%;">' +
                  '<p style="text-align: justify;">Terima kasih atas partisipasi Anda dalam kegiatan Asesmen Hard Competency kali ini.Kontribusi Anda akan menjadi wawasan berharga dalam mengukur tingkat pemenuhan kebutuhan kompetensi jabatan pegawai. Semoga hasil dari asesmen ini dapat menjadi landasan untuk pengembangan kompetensi individu dan peningkatan kinerja di masa depan</p>' +
                  "</div>" +
                  "</div>",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Lanjutkan",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push({
                    name: "dashboard-assesmen-hc",
                  });
                }
              });
            }
          });
        })
        .catch((error) => {
          Swal.close();
          console.error("Error in Axios request:", error); // Log any errors
          this.axiosCatchError = error.response.data;
        });
    },
  },
  async mounted() {
    this.cekParamSoal();
  },
};
</script>
<template>
  <div class="container">
    <HeaderAsesmenHC />
    <div class="card mb-0" style="border-radius: 5px">
      <div class="card-header bg-primary text-white font-size-16" style="border-radius: 5px">Asesmen HC</div>
      <div class="card-body">
        Selamat, Anda telah berhasil menyelesaikan seluruh soal asesmen ini. Selanjutnya mohon kesediannya untuk memberikan umpan balik berupa masukan dan saran terhadap kegiatan asesmen yang telah
        selesai diikuti pada kolom dibawah ini guna peningkatan kualitas asesmen di masa mendatang.
        <br />
        <br />
        <span>Kritik & Saran</span> <br />
        <br />
        <textarea v-model="kritik_dan_saran" style="width: 100%; height: 200px"></textarea>
        <br />
        <button class="btn btn-success d-block mx-auto" style="width: 200px" @click="putStatusPeserta">Simpan</button>
      </div>
    </div>
  </div>
  <b-modal id="modal-center" v-model="modalTerimakasih" centered title="Terimakasih">
    <p class="my-4">
      Kepada Yth. Peserta Asesmen Hard Competency, <br />
      Terima kasih kepada Peserta Ujian Asesmen Hard Competency yang telah berpartisipasi dalam ujian. Kesediaan Anda untuk mengikuti proses asesmen ini dengan penuh komitmen dan profesionalisme.
      Kehadiran dan partisipasi Anda tidak hanya menunjukkan dedikasi terhadap pengembangan kompetensi pribadi, tapi juga kontribusi nyata terhadap peningkatan kualitas sumber daya. <br />
      Semoga setiap usaha yang Anda tanamkan dalam menghadapi ujian ini dapat menjadi investasi berharga untuk perkembangan pribadi dan profesional Anda. Teruslah melangkah maju dengan keyakinan, dan
      kami yakin hasil dari asesmen ini akan menjadi pijakan kokoh untuk pencapaian-pencapaian lebih besar di masa depan. Terima kasih sekali lagi, dan mari bersama-sama menyongsong perjalanan yang
      penuh prestasi dan kesuksesan.
    </p>
  </b-modal>
</template>
<style scoped>
.nav-head {
  max-width: 1000px !important;
}

.search-pad {
  padding-left: 5px !important;
}

.input-group {
  display: flow;
}

.search-box {
  display: flow;
  align-items: center;
  background-color: #f8f8fb;
  border-radius: 40px;
}

.search-icon {
  position: static;
  line-height: 30px;
}

.search-input input {
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: unset;
  padding: 5px 0px 5px 15px;
}

.position-relative {
  position: relative;
}

.span-notif {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 8px;
}

.icon-notif {
  position: relative;
  top: 5px;
}

.nav-pills .nav-link {
  border-radius: 5rem;
}
</style>
