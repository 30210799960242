<template>
  <div class="card mt-2" style="border-radius: 5px">
    <div class="card-body pt-1 pb-1">
      <div class="row my-2">
        <div class="col-xl-11 font-size-16 my-auto">
          <h5 class="pt-1">ASSESMEN HARD COMPETENCY</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
